<template>
  <div id="sms-send">
    <div id="form-box">
      <el-form :model="form" ref="formRef" :rules="formRules">
        <el-form-item label="发送模式" label-width="160px" prop="">
          <el-radio-group v-model="sendMode" @change="changeMode">
            <el-radio
              label="手机号发送"
              :value="0"
              size="large"
              border
            ></el-radio>
            <el-radio
              label="个性化发送"
              :value="1"
              size="large"
              border
            ></el-radio>
            <el-radio
              label="变量模板发送"
              :value="2"
              size="large"
              border
            ></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :label="$t('号码文件')"
          label-width="160px"
          prop=""
          v-if="sendMode == 0"
        >
          <div>
            <el-button type="primary" @click="chooseFile"
              ><i class="fa-solid fa-cloud-arrow-up"></i
              >{{ fileList[0]?.name || $t("点击上传号码文件") }}</el-button
            >
            <el-button text @click="fileList = []">清空文件</el-button>
            <el-button text @click="downloadDemo">下载示例</el-button>
            <div style="margin-top: 10px">
              <el-alert
                title="号码文件支持xlsx格式文件，每行一个手机号，最大支持十万个手机号"
                type="warning"
                :closable="false"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('号码文件')"
          label-width="160px"
          prop=""
          v-if="sendMode == 1"
        >
          <div>
            <el-upload
              ref="uploadRef2"
              v-model:file-list="fileList2"
              action="/api/api/uploadBatch"
              :headers="{ Authorization: $store.state.token }"
              name="phoneFile"
              :data="fileData2"
              accept=".xlsx"
              :on-success="uploadSuccess"
              :on-error="onError"
              :on-progress="onProgress"
              :auto-upload="false"
              :limit="1"
            >
              <el-button type="primary"
                ><i class="fa-solid fa-cloud-arrow-up"></i
                >{{ $t("点击上传号码文件") }}</el-button
              >
              <template #tip>
                <div style="margin-top: 10px">
                  <el-alert
                    title="号码文件支持xlsx格式文件，每行第一列为手机号，第二列为对应的发送内容，最大支持十万个手机号"
                    type="warning"
                    :closable="false"
                  />
                </div>
              </template>
            </el-upload>
            <el-button text @click="downloadDemo2">下载示例</el-button>
          </div>
        </el-form-item>
        <el-form-item
          label="数据文件"
          label-width="160px"
          prop=""
          v-if="sendMode == 2"
        >
          <div>
            <el-button type="primary" @click="chooseFile"
              ><i class="fa-solid fa-cloud-arrow-up"></i
              >{{ fileList[0]?.name || "上传文件" }}</el-button
            >
            <el-button text @click="fileList = []">清空文件</el-button>
            <el-button text @click="downloadDemo3">下载模板</el-button>
            <div style="margin-top: 10px">
              <el-alert
                title="数据文件支持xlsx格式文件1. 号码固定为第一列；2. 点击【插入变量】即可在内容模板光标处增加变量参数，根据文件中对应的参数列替换掉内容模板中的变量参数，生成完整短信内容；上传文件和插入变量后点击预览可在下方预览生成的部分短信内容。"
                type="warning"
                :closable="false"
              />
            </div>
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('手机号')"
          label-width="160px"
          prop="mobile"
          v-if="sendMode == 0 && fileList.length == 0"
        >
          <el-input
            v-model="form.mobile"
            @blur="inputBlur"
            :rows="10"
            type="textarea"
            placeholder="回车换行，每行一个手机号"
            style="
              font-family: '\5FAE\8F6F\96C5\9ED1', 'Helvetica Neue', Helvetica,
                Arial, sans-serif;
            "
            :disabled="fileList.length > 0"
          />
          <div style="font-size: 12px; color: #606266">
            {{ $t("当前输入") }} {{ total }} {{ $t("个号码") }}
          </div>
        </el-form-item>
        <el-form-item
          :label="$t('通讯录')"
          label-width="160px"
          prop=""
          v-if="sendMode == 0 && fileList.length == 0"
        >
          <el-tree
            style="width: 100%"
            ref="treeRef"
            :data="tree_data"
            node-key="id"
            :load="loadNode"
            lazy
            @node-click="handleNodeClick"
            show-checkbox
            @check="currentChange"
          >
            <template #empty>
              <div style="display: flex">{{ $t("加载中") }}...</div>
            </template>
            <template #default="{ node, data }">
              <span v-if="data.data.type == 10">
                <i class="fa-solid fa-folder-open"></i>
                <span>{{ data.title }}</span>
              </span>
              <span v-if="data.data.type == 20">
                <i class="fa-solid fa-user"></i>
                <span>{{ data.title }}</span>
              </span>
            </template>
          </el-tree>
        </el-form-item>
        <el-form-item
          :label="$t('发送内容')"
          label-width="160px"
          prop="content"
          v-if="sendMode == 0"
        >
          <el-input
            v-model="form.content"
            :rows="10"
            type="textarea"
            :placeholder="$t('发送内容')"
            @input="contentChange"
          />
          <div style="font-size: 12px; color: #606266">
            {{ $t("短信内容")
            }}<span class="textStyle">{{ form.content.length }}</span
            >{{ $t("个字") }}，{{ $t("短信签名") }} 【<span class="textStyle">{{
              smsSignatureNum
            }}</span
            >】{{ $t("个字") }}。{{
              $t("70个字以内算1条,超过70个字按每条67个字拆分，共拆分")
            }}<span class="textStyle">{{ smsNum }}</span
            >条
          </div>
        </el-form-item>
        <el-form-item
          label="内容模板"
          label-width="160px"
          prop="content"
          v-if="sendMode == 2"
        >
          <el-input
            v-model="form.content"
            :rows="10"
            type="textarea"
            placeholder="内容模板"
            @input="contentChange"
            @blur="getCursorPosition($event)"
          />
          <!-- <div style="font-size: 12px; color: #606266">
            {{ $t("短信内容")
            }}<span class="textStyle">{{ form.content.length }}</span
            >{{ $t("个字") }}，{{ $t("短信签名") }} 【<span class="textStyle">{{
              smsSignatureNum
            }}</span
            >】{{ $t("个字") }}。{{
              $t("70个字以内算1条,超过70个字按每条67个字拆分，共拆分")
            }}<span class="textStyle">{{ smsNum }}</span
            >条
          </div> -->
        </el-form-item>
        <el-form-item
          label="参数列"
          label-width="160px"
          prop=""
          v-if="sendMode == 2"
        >
          <el-select
            v-model="currentColumn"
            :placeholder="$t('请选择')"
            style="width: 220px"
          >
            <el-option
              v-for="item in 20"
              :key="item"
              :label="`第${item}列`"
              :value="item"
            />
          </el-select>
          <el-button @click="insertVariable">插入变量</el-button>
          <el-button
            type="primary"
            text
            @click="preview"
            :loading="previewLoading"
            ><i class="fa-solid fa-eye"></i>预览</el-button
          >
        </el-form-item>
        <el-form-item :label="$t('选择产品')" label-width="160px" prop="appId">
          <el-select
            v-model="form.appId"
            :placeholder="$t('请选择')"
            filterable
            style="width: 220px"
          >
            <el-option
              v-for="item in appList"
              v-show="item.enabled === 100"
              :key="item.appId"
              :label="item.appId"
              :value="item.appId"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('定时发送')" label-width="160px" prop="">
          <el-date-picker
            v-model="form.atTime"
            type="datetime"
            value-format="YYYY-MM-DD HH:mm:ss"
            :placeholder="$t('请选择时间')"
            :disabled-date="
              (time) => {
                return time < new Date();
              }
            "
          />
          <span style="padding-left: 10px"
            ><i class="fa-solid fa-circle-exclamation"></i
            >{{ $t("不选择时间则立即发送") }}</span
          >
        </el-form-item>
        <el-form-item label="" prop="" label-width="100px">
          <el-button
            type="primary"
            size="large"
            style="width: 120px"
            @click="smsSend()"
            :loading="sendLoading"
            ><i class="fa-solid fa-paper-plane"></i>{{ $t("发送") }}</el-button
          >
          <download-excel
            :data="json_data"
            :fields="json_fields"
            :name="`${$t('详情')}.xls`"
            v-if="json_data.length > 0"
          >
            <span class="downloadTxt"
              ><i class="fa-solid fa-circle-arrow-down"></i
              >{{ $t("下载") }}</span
            >
          </download-excel>
        </el-form-item>
        <el-form-item
          label=""
          prop=""
          label-width="100px"
          v-if="sendMode == 2 && previewExamples.length > 0"
        >
          <!-- 预览模板短信 -->
          共有 {{ previewTotal }} 个号码,部分预览如下:
          <el-table :data="previewExamples" border>
            <el-table-column prop="mobile" label="手机号" />
            <el-table-column prop="content" label="内容" />
          </el-table>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div style="position: relative; width: 500px">
        <img src="../../image/4315.png" alt="" style="width: 500px" />
        <div
          style="
            position: absolute;
            top: 191px;
            left: 80px;
            width: 342px;
            height: 480px;
            overflow-y: auto;
            padding: 30px 0 40px 20px;
          "
        >
          <div
            class="bubble-box2"
            v-html="showContent"
            v-if="showContent"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "smsSend",
};
</script>
<script setup>
import { reactive, onBeforeMount, ref, onMounted, onActivated } from "vue";
import {
  send,
  upload,
  uploadBatch,
  downloadDemoMobile,
  downloadDemoMobileBatch,
  downloadDemoMobileTemplate,
  echoTmplData,
  uploadTemplate,
} from "@/http/api/smsSend.js";
import { getAppIdList } from "@/http/api/appManage";
import { useStore } from "vuex";
import { ElMessage, ElLoading, ElMessageBox } from "element-plus";
import { getAddressBookList } from "@/http/api/addressBook";
import md5 from "js-md5";
import { binaryToExcelExport } from "@/utils/tool";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
onActivated(() => {
  getUserAppList();
});
const store = useStore();
let userInfo = JSON.parse(window.localStorage.getItem("userInfo")) || "";

let appList = ref([]);

const uploadRef = ref(null);

const formRef = ref(null);
const formRules = reactive({
  // appId: [{ required: true, message: t("必填"), trigger: "blur" }],
  // mobile: [{ required: true, message: t("必填"), trigger: "blur" }],
  // content: [{ required: true, message: t("必填"), trigger: "blur" }],
});
// 表单数据
const form = reactive({
  appId: "",
  mobile: "",
  content: "",
  atTime: "",
  addressBookIds: [],
  username: store.state.userInfo.username || userInfo.username,
  // password: store.state.userInfo.password || userInfo.password,
  report: true,
  extendCode: "",
});

const json_data = ref([]);
let json_fields = {
  mobile: "mobile",
  msgId: {
    field: "msgId",
    callback: (value) => {
      return `${value}`;
    },
  },
};

onBeforeMount(async () => {
  init();
});
// 获取当前用户下的app列表
const getUserAppList = async () => {
  const res = await getAppIdList({ page: 1, pageSize: 9999 });
  if (res.code === 0) {
    appList.value = res.data.list || [];
  }
};
onMounted(async () => {
  inputBlur();
});

// 计算号码输入框号码数目
const total = ref(0);
var mobileReg = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/;
const totalFn = () => {
  // 去重
  // form.mobile = [...new Set(form.mobile)];
  for (let i = 0; i < form.mobile.length; i++) {
    // if (mobileReg.test(form.mobile[i] && form.mobile[i]!='')) {
    //   total.value += 1;
    // }
    if (form.mobile[i] != "") {
      total.value += 1;
    }
  }
};

const inputBlur = () => {
  //输入框失焦时进行计算
  total.value = 0;
  if (typeof form.mobile === "object") {
    totalFn();
  } else {
    form.mobile = form.mobile.split("\n");
    totalFn();
    form.mobile = form.mobile.join("\n");
  }
};

// 上传号码文件成功的钩子
const uploadSuccess = async (res, uploadFile, uploadFiles) => {
  if (res.code === 0) {
    // form.mobile = res.data.join("\n");
    // total.value = res.data.length;
    // ElMessage({
    //   message: res.msg,
    //   type: "success",
    // });
    ElMessageBox.confirm("", res.msg, {
      showCancelButton: false,
      confirmButtonText: "好的",
      closeOnClickModal: false,
      type: "success",
      center: true,
      showClose: false,
    });
  } else {
    ElMessage({
      message: res.msg,
      type: "error",
      offset: window.screen.height / 3,
    });
  }
  sendLoading.value = false;
};
//上传时进度
const onProgress = async (evt, uploadFile, uploadFiles) => {
  //
};

// 上传失败钩子
const onError = (error) => {
  ElMessage({
    message: "上传异常！报错信息：" + error,
    type: "error",
    offset: window.screen.height / 3,
  });
  sendLoading.value = false;
};

// 单条发送返回的结果
// const resData = reactive({
//   msgId: "",
//   msg: "",
// });
// 发送短信
let fileList = ref([]); //文件列表
let fileData = {};

const sendLoading = ref(false);
const smsSend = async () => {
  sendLoading.value = true;
  let res;
  //手机号发送 模式
  if (sendMode.value == 0) {
    let obj = { ...form };
    // obj.password=md5(obj.password)
    obj.atTime = obj.atTime || "";
    if (
      (total.value >= 1 ||
        form.addressBookIds.length > 0 ||
        fileList.value.length > 0) &&
      form.content &&
      form.appId
    ) {
      // 如果是号码文件发送
      if (fileList.value.length > 0) {
        const formData = new FormData();
        formData.append("phoneFile", fileList.value[0]);
        formData.append("userName", obj.username);
        formData.append("content", obj.content);
        formData.append("appId", obj.appId);
        formData.append("atTime", obj.atTime || "");
        // 调用导入接口并显示进度条
        try {
          // 调用uploadFile函数进行文件上传
          res = await upload(formData, (p) => {
            //
          });
          // 这里可以在上传成功后执行一些操作，如显示上传成功的提示信息
          if (res.code == 0) {
            // ElMessage({
            //   type: "success",
            //   message: res.msg,
            // });
            ElMessageBox.confirm("", res.msg, {
              showCancelButton: false,
              confirmButtonText: "好的",
              closeOnClickModal: false,
              type: "success",
              center: true,
              showClose: false,
            });
          }
        } catch (error) {
          // 这里可以在上传失败后执行一些操作，如显示上传失败的提示信息
          ElMessage({
            type: "error",
            message: error,
            offset: window.screen.height / 3,
          });
        }
      } else {
        //输入框号码发送
        obj.mobile = obj.mobile.split("\n"); //转数组
        // 去空
        obj.mobile = obj.mobile.filter((item) => item && item.trim());
        // 去重
        // obj.mobile = [...new Set(obj.mobile)];
        res = await send(obj);
        if (res.code == 0 && res.data.file) {
          let content = res.data.file;
          // 使用atob方法解码base64
          var raw = window.atob(content);
          // 创建一个存储解码后数据的数组
          var uInt8Array = new Uint8Array(raw.length);
          // blob只能接收二进制编码，需要将base64转为二进制
          for (var i = 0; i < raw.length; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
          }
          binaryToExcelExport(uInt8Array, `产品${form.appId}.xlsx`);
          // ElMessage({
          //   type: "success",
          //   message: res.msg,
          // });
          ElMessageBox.confirm("", res.msg, {
            showCancelButton: false,
            confirmButtonText: "好的",
            closeOnClickModal: false,
            type: "success",
            center: true,
            showClose: false,
          });
        } else if (res.code == 0) {
          json_data.value = res.data;
          // ElMessage({
          //   type: "success",
          //   message: res.msg,
          // });
          ElMessageBox.confirm("", res.msg, {
            showCancelButton: false,
            confirmButtonText: "好的",
            closeOnClickModal: false,
            type: "success",
            center: true,
            showClose: false,
          });
        }
        // if (res.code != 0) {
        //   ElMessage({
        //     type: "error",
        //     message: res.msg || "error",
        //   });
        // }
      }
    } else {
      // resData.msgId = "";
      // resData.msg = "";
      ElMessage({
        message: t("缺少必填项！"),
        type: "warning",
        offset: window.screen.height / 3,
      });
    }
  } else if (sendMode.value == 1) {
    //个性化发送
    if (fileList2.value.length > 0 && form.appId) {
      // 清空
      uploadRef2.value.clearFiles();
      // 手动选取第一个
      uploadRef2.value.handleStart(fileList2.value[0].raw);
      fileData2.userName = form.username;
      fileData2.appId = form.appId;
      fileData2.atTime = form.atTime || "";
      uploadRef2.value.submit();
    } else {
      ElMessage({
        message: t("缺少必填项！"),
        type: "warning",
        offset: window.screen.height / 3,
      });
    }
  } else if (sendMode.value == 2) {
    //变量模板发送
    let obj = { ...form };
    if (fileList.value.length > 0 && obj.content && obj.appId) {
      const formData = new FormData();

      formData.append("phoneFile", fileList.value[0]);
      formData.append("userName", obj.username);
      formData.append("content", obj.content);
      formData.append("appId", obj.appId);
      formData.append("atTime", obj.atTime || "");
      // 调用导入接口并显示进度条
      try {
        // 调用uploadFile函数进行文件上传
        res = await uploadTemplate(formData, (p) => {});
        // 这里可以在上传成功后执行一些操作，如显示上传成功的提示信息
        if (res.code == 0) {
          ElMessageBox.confirm("", res.msg, {
            showCancelButton: false,
            confirmButtonText: "好的",
            closeOnClickModal: false,
            type: "success",
            center: true,
            showClose: false,
          });
        }
      } catch (error) {
        // 这里可以在上传失败后执行一些操作，如显示上传失败的提示信息
        ElMessage({
          type: "error",
          message: error,
          offset: window.screen.height / 3,
        });
        // await fileList.value[0].slice(0 ,1).arrayBuffer();
      }
    } else {
      if (!obj.appId) {
        ElMessage({
          type: "error",
          message: "未选择产品！",
          offset: window.screen.height / 3,
        });
      }
      if (!obj.content) {
        ElMessage({
          type: "error",
          message: "未输入内容！",
          offset: window.screen.height / 3,
        });
      }
      if (fileList.value.length == 0) {
        ElMessage({
          type: "error",
          message: "请检查文件是否上传！",
          offset: window.screen.height / 3,
        });
      }
    }
  }
  sendLoading.value = false;
};

// ----------------------------手机和统计内容
const showContent = ref("");
// 短信内容输入统计
let smsNum = ref(0); //短信内容字数
let smsSignatureNum = ref(0); //短信签名字数
const contentChange = () => {
  if (form.content.length === 0) {
    smsNum.value = 0;
  } else if (form.content.length > 0 && form.content.length <= 70) {
    smsNum.value = 1;
  } else {
    smsNum.value = Math.ceil(form.content.length / 67);
  }

  let startIndex = form.content.indexOf("【");
  let endIndex = form.content.lastIndexOf("】");
  if (startIndex != -1 && endIndex != -1 && startIndex < endIndex) {
    smsSignatureNum.value = endIndex - startIndex - 1;
  } else {
    smsSignatureNum.value = 0;
  }
  showContent.value = form.content;
  showContent.value = showContent.value.replace(/\n/g, "<br/>");
};

// -----------------------------通讯录
const treeRef = ref(null);
const tree_data = ref([]);
const init = async () => {
  const res = await getAddressBookList({ fatherId: 0 });
  if (res.code === 0) {
    let arr = res.data.list || [];
    tree_data.value = arr;
  }
};
// 加载数据
const loadNode = async (node, resolve) => {
  if (node.level == 0) {
    return resolve(tree_data.value);
  }
  let id = node.data.data.id;
  const res = await getAddressBookList({ fatherId: id });
  if (res.code === 0) {
    let arr = res.data.list || [];
    for (let i = 0; i < arr.length; i++) {
      arr[i].value = arr[i].id;
    }
    resolve(arr);
  }
};
const currentChange = (data, arr4) => {
  // 通讯录select变化时调用
  form.addressBookIds = arr4.checkedKeys;
};
const handleNodeClick = async (a, b, c, d) => {};

// --------------------------------

const sendMode = ref(0);
const changeMode = (val) => {
  // 清空
  // uploadRef.value.clearFiles();
  fileList.value = [];
  form.content = "";
  showContent.value = "";
  previewExamples.value = [];
};
let fileList2 = ref([]); //文件列表
let fileData2 = {};
const uploadRef2 = ref(null);

const chooseFile = () => {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = ".xlsx";
  // input.multiple = true;
  input.addEventListener("change", (event) => {
    fileList.value = event.target.files;
    // 处理选择的文件
    form.addressBookIds = [];
    form.mobile = "";
  });
  input.click();
};

const downloadDemo = async () => {
  const res = await downloadDemoMobile();
  // if (res.status == 200 && res.statusText == "OK") {
  // binaryToExcelExport(res, "号码示例文件");
  // }
};
const downloadDemo2 = async () => {
  const res = await downloadDemoMobileBatch();
  // if (res.status == 200 && res.statusText == "OK") {
  // binaryToExcelExport(res, "个性化发送示例文件");
  // }
};
// 变量模板发送模式
const downloadDemo3 = async () => {
  const res = await downloadDemoMobileTemplate();
  // if (res.status == 200 && res.statusText == "OK") {
  // binaryToExcelExport(res, "template");
  // }
};
const previewLoading = ref(false);
const previewExamples = ref([]);
const previewTotal = ref(0);
const preview = async () => {
  if (fileList.value && fileList.value.length > 0 && form.content) {
    previewLoading.value = true;
    const formData = new FormData();
    formData.append("phoneFile", fileList.value[0]);
    formData.append("content", form.content);
    try {
      const res = await echoTmplData(formData, (p) => {});
      if (res.code == 0) {
        previewExamples.value = res.data?.examples ?? [];
        previewTotal.value = res.data?.total ?? 0;
      } else {
        previewExamples.value = [];
      }
    } catch (error) {
      ElMessage({
        type: "error",
        message: error,
        offset: window.screen.height / 3,
      });
    }
  } else {
    ElMessage({
      message: "请选择文件和内容！",
      type: "warning",
      offset: window.screen.height / 3,
    });
  }
  previewLoading.value = false;
};
let currentColumn = ref(1);
let cursorPosition = 0;
//获取输入框失去焦点时的光标位置
const getCursorPosition = (e) => {
  cursorPosition = e.target.selectionStart;
};
const insertVariable = () => {
  let str = form.content;
  let insertStr = `{{.column${currentColumn.value}}}`;
  // 将字符串分割为两部分，并在指定位置插入新内容
  const firstPart = str.slice(0, cursorPosition);
  const secondPart = str.slice(cursorPosition);
  form.content = firstPart + insertStr + secondPart;
  contentChange();
};
</script>

<style lang="scss" scoped>
.textStyle {
  font-weight: 900;
  font-size: 18px;
  color: red;
}

.downloadTxt {
  cursor: pointer;
  margin-left: 10px;
  &:hover {
    color: #409eff;
  }
}

.bubble-box2 {
  position: relative;
  color: #000000;
  font-size: 14px;
  border: 2px solid #e5e5e7;
  background: #e5e5e7;
  border-radius: 20px;
  max-width: 200px;
  display: inline-block;
  word-wrap: break-word;
  white-space: break-spaces;
  padding: 10px;
}

.bubble-box2::before {
  content: " ";
  position: absolute;
  z-index: 1;
  bottom: -5px;
  left: -14px;
  height: 22px;
  border-right: 20px solid #e5e5e7;
  border-bottom-right-radius: 15px 15px;
  transform: translate(0, -2px);
}

.bubble-box2::after {
  content: " ";
  position: absolute;
  z-index: 2;
  bottom: -5px;
  left: 2px;
  width: 26px;
  height: 22px;
  background: #fff;
  border-bottom-right-radius: 10px;
  transform: translate(-30px, -2px);
}

// 手机适配
#sms-send {
  display: flex;
}
#form-box {
  width: calc(100vw - 700px);
  margin: 30px;
}
@media screen and (max-width: 1100px) {
  #sms-send {
    flex-direction: column;
    #form-box {
      width: 90%;
    }
  }
}

::v-deep(.el-checkbox) {
  margin-bottom: 0 !important;
}
::v-deep(label) {
  margin-bottom: 0;
}
</style>
<style lang="scss">
.el-col-24 {
  width: 80vw;
}
</style>
